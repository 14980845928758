import React from 'react';

import {
  StaticMenuList, CopyRight, TabBarStyle,
} from '@adac/core-view';
import { links } from '../../content/index';


export default () => (
  <TabBarStyle padding>
    <CopyRight subTitle>
      © 2019 ADAC
    </CopyRight>
    <StaticMenuList items={links.footer} />
  </TabBarStyle>
);
