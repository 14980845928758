import React from 'react';

import {
  Title, Page, SubPage,
} from '@adac/core-view';
import {
  __,
} from '@adac/core-model';
import ClosedCasesList from '../composite/ClosedCasesList';

export default () => (
  <Page>
    <SubPage>
      <Title>
        {__('All closed cases')}
      </Title>
    </SubPage>
    <SubPage>
      <ClosedCasesList withPagination withExport />
    </SubPage>
  </Page>
);
