import React from 'react';
import styled from 'styled-components';
import { View, Icon } from '@adac/core-view';
import { Link } from 'react-router-dom';
import { __ } from '@adac/core-model';
import NavBarContainer from './NavBarContainer';
import Profile from '../composite/Profile';

const HeaderStyles = styled(View)`
  display: grid;
  grid-template-columns: max-content max-content 1fr max-content;
  padding-left: 14px;
  grid-gap: 14px;
  width: 100%;
  align-items: center;
`;

export const Header = () => (
  <HeaderStyles>
    <Link to="/"><Icon name="adac" type="ico.logo" size="24px" /></Link>
    <View background="white" padding="4px" compact={false} height="max-content"><Link to="/">{__('SND PARTNER ADMIN')}</Link></View>
    <NavBarContainer />
    <Profile />
  </HeaderStyles>
);
