import { CaseRecord, getApiRoutes, getListWithHeaders as getList } from '@adac/core-model';
import { Params } from '../components/ListView';

export interface Case extends CaseRecord {
  // id: number;
  // token: string;
  // customerAddressStreet: string;
  // customerAddressNumber: string | number;
  // customerAddressZip: number;
  // customerAddressCity: string;
}

export type ClosedCase = Case;
export type OpenCase = Case;

export interface GetterType {
  data: Case[];
  total: number | undefined;
}

const buildURL = (url: string, params?: Params) => {
  const queryString = params ? Object.entries(params).map(([key, value]) => `${key}=${encodeURIComponent(value)}`).join('&') : '';
  return `${url}${queryString ? `?${queryString}` : ''}`;
};

export const getClosedCases = async (token: string, params?: Params): Promise<GetterType> => {
  const url = buildURL(getApiRoutes().partner.caseList.closed, params);

  const closedCases = await getList<ClosedCase>(url, token);
  return { data: closedCases.data, total: closedCases.total };
};

export const getOpenCases = async (token: string, params?: Params): Promise<GetterType> => {
  const url = buildURL(getApiRoutes().partner.caseList.open, params);

  const openCases = await getList<OpenCase>(url, token);
  return { data: openCases.data, total: openCases.total };
};
