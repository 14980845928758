/* eslint-disable no-return-await */
import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react';
import { Link, RouteComponentProps } from 'react-router-dom';

import {
  Page, SubPage, Title, SubTitle, LightSubTitle, MapContainer, Divider, UserInfo, Cell, Image,
  LoadingSpinner, useLocation, Icon, MobileBackButton,
  CaseDocuments, InvoiceDocument,
  WarningMessage,
  AddressOverlayContainer,
  extractConfirmedAddressObject,
  createAddressString,
  Button,
  ButtonTitle,
  ColumnLayout,
  useReportRequest,
  useAsyncAction,
  useCaseDetails,
  getCaseDetailsForPartner,
  ProductType,
  ViewProvider,
  searchCaseByTokenForPartner,
  CopyableInfoItem,
  Copyable,
} from '@adac/core-view';

import {
  CaseStatusList, uploadInvoice, __, config,
  cipherSndOfferLink,
  request,
  CaseReportDTO,
  isCaseCancelled,
  isCaseWithdrawnByHermes,
  Document,
  isCaseClosedByBackoffice,
  getClaimReference,
  getCompanyTypeWithContactManagerId,
  isCaseCompleted,
  CipheredToken,
} from '@adac/core-model';

import styled from 'styled-components';
import StoreContext from '../../stores';
import { useMyCompany } from '../../hooks/useMyCompany';


const HighlightedProductType = styled(ProductType)`
  & > .productType > p:nth-of-type(2) {
    background-color: #FFCC01;
  }
  gap: 200px;

  @media screen and (max-width: 600px) {
    gap: 20px;
  }
`;


const CopyableUserInfo = styled(UserInfo)`
  & > #name {
    grid-template-columns: 1fr 1fr 1fr;
    & > * {
      width: 100%;
    }
  }
`;


const CaseDetail = observer((props: RouteComponentProps<{ token: string }>) => {
  const { auth: authStore } = useContext(StoreContext);
  const [invoice, setInvoice] = useState<Document|null>(null);
  const { goTo } = useLocation();
  const myCompany = useMyCompany();

  const currentCompanyId = myCompany?.id;
  const token = props.match.params.token as CipheredToken; // TODO

  const { customerCoords } = useCaseDetails([token, authStore.token], getCaseDetailsForPartner);
  const { requestUrl } = useReportRequest(token, currentCompanyId);

  const { resource } = useAsyncAction(async () => await request<CaseReportDTO>(requestUrl), [requestUrl, invoice]);
  const { confirmedAddress: confirmedAddressFromServer/* , case: caseObj */ } = resource || {};


  const getCaseDTOAction = async () => await searchCaseByTokenForPartner(token, {
    companyId: currentCompanyId,
    reportView: window.location.pathname.match(/\/view/)?.[0] || '',
  });

  const { resource: caseDTO, hasLoaded } = useAsyncAction(getCaseDTOAction, [currentCompanyId, token, JSON.stringify(invoice)]);

  const driverInfo = caseDTO?.driverInfo;
  const caseData = caseDTO?.case;

  const customerFullAddressString = (caseDTO && createAddressString(caseDTO.case)) || '';
  const finalConfirmedAddress = extractConfirmedAddressObject(confirmedAddressFromServer);
  const finalConfirmedAddressString = finalConfirmedAddress ? createAddressString(finalConfirmedAddress) : customerFullAddressString;

  const isCompleted = (from?: 'cancel' | 'manual' | 'withdraw' | 'completed') => caseDTO?.status.event === CaseStatusList.CASE_COMPLETED.toString() && (
    !from
    || (from === 'cancel' && isCaseCancelled(caseDTO?.status.from) && !isCaseWithdrawnByHermes(caseDTO?.status.from))
    || (from === 'manual' && isCaseClosedByBackoffice(caseDTO?.status.from))
    || (from === 'withdraw' && isCaseWithdrawnByHermes(caseDTO?.status.from))
    || (from === 'completed' && isCaseCompleted(caseDTO?.status.from))
  );
  console.log('isCompleted', isCompleted('completed'), isCompleted('manual'), isCompleted('cancel'));

  const driverHasFinished = caseDTO?.status.event === CaseStatusList.OPENING_COMPLETED.toString();


  const isInvoiceUploadAllowed = myCompany && myCompany.id === caseDTO?.companyInfo?.id && config.invoiceModule.isInvoiceUploadAllowed({
    event: `${caseDTO?.status?.event}`, data: caseDTO?.status.data, case: caseDTO?.case,
  }, myCompany.type);

  const onUpload = async (file: File) => {
    const result = await uploadInvoice(`${authStore.token}`, token, file);
    setInvoice(result);
  };

  if (!token || !hasLoaded) {
    return (
      <Page>
        <MobileBackButton />
        <LoadingSpinner>{__('Loading case detail for your company for')} {token}</LoadingSpinner>
      </Page>
    );
  }

  const commissioningReference = caseData?.commissioningReference;
  const formattedCommissioningReference = commissioningReference
    ? `${getClaimReference(commissioningReference)}/${getCompanyTypeWithContactManagerId(commissioningReference)}`
    : '';

  return (
    <Page>
      <AddressOverlayContainer>
        <MobileBackButton />
        <SubPage>
          <Title>{customerFullAddressString}</Title>
          <Copyable style={{ width: 'fit-content' }} content={formattedCommissioningReference}>
            <LightSubTitle>
              {__('Hermes Id')}: {formattedCommissioningReference}
            </LightSubTitle>
          </Copyable>
        </SubPage>

        <MapContainer
          style={{ height: '160px' }}
          customerPosition={customerCoords}
        />

        <SubPage>
          {(isCompleted() || driverInfo) ?(
            <>
              <SubTitle>{__('Beauftragter Mitarbeiter')}</SubTitle>

              <Cell
                isOpen={false}
                title={
                  <SubTitle>{driverInfo ? `${driverInfo.driver?.firstName} ${driverInfo.driver?.familyName}` : __('No driver information')}</SubTitle>
                }
                description=""
                onClick={() => (driverInfo ? goTo(`/driver/edit/${driverInfo.driver?.id}`, { prefixed: false }) : undefined)}
                orderView={() => <Image style={{ width: 24, marginRight: 18 }} source={driverInfo ? driverInfo.driver?.photo : null} />
                }
                rightView={<Icon name="arrow-right" />}
              />

              <Divider margin="24px" fullWidth />
            </>
          ): (
            <ColumnLayout margin="10px" ratio="1fr">
              <Link to={{ pathname: cipherSndOfferLink(currentCompanyId ?? null, token) }} target="_blank" rel="noopener noreferrer">
                <Button type="submit" cta>
                  <ButtonTitle>{__('Dispatch a Driver')}</ButtonTitle>
                </Button>
              </Link>
            </ColumnLayout>
          )}

          <CopyableUserInfo
            title={caseData?.title || ''}
            firstName={caseData?.customerFirstName || ''}
            lastName={caseData?.customerFamilyName || ''}
            phone={caseData?.customerPhone}
            address={finalConfirmedAddressString}
            Item={CopyableInfoItem}
          />

          <Copyable content={caseData?.productNumber || ''}>
            <HighlightedProductType
              productType={caseData?.productType}
              productNumber={caseData?.productNumber}
            />
          </Copyable>

          {isCompleted() && !resource?.case?.invoiceDocument && (
            <WarningMessage>
              <>{isCompleted('manual') && __('Case close manually in OpenHome')}</>
              <>{isCompleted('withdraw') && __('Case has been withdrawn')}</>
              <>{isCompleted('cancel') && __('Case has been cancelled')}</>
              <>{(isCompleted('withdraw') || isCompleted('manual') || isCompleted('completed')) && __(isInvoiceUploadAllowed ? 'Please upload invoice' : 'Invoice upload disabled')}</>
            </WarningMessage>
          )}

          {/* TODO: for PBI 110735 Implement case event history into snd partner admin */}
          {/* <CaseStatusEventHistory
            isLoading={isLoading}
            history={{ push: goTo }}
            items={caseDetails?.logs}
          /> */}

          {
            isCompleted() && (
              <>
                <Divider margin="24px" fullWidth />
                <CaseDocuments
                  token={token}
                  commissionReport={caseData?.commissionReport}
                  commissionExtraDamage={caseData?.commissionExtraDamage}
                  finalStatusReport={caseData?.finalStatusReport?.partner}
                >
                  <InvoiceDocument
                    token={token}
                    // caseData?.invoiceDocument is not trusted ATM, as not always present value
                    invoiceDocument={resource?.case?.invoiceDocument}
                    onUpload={(isInvoiceUploadAllowed && onUpload) || undefined}
                    accept="application/pdf"
                  />
                </CaseDocuments>
              </>
            )
          }
          {driverHasFinished && (
            <>
              <Divider margin="24px" fullWidth />
              <WarningMessage>{__('Evidence PDF files are being generated, please wait...')}</WarningMessage>
            </>
          )}

        </SubPage>
      </AddressOverlayContainer>
    </Page>
  );
});

export default observer((props: RouteComponentProps<{ token: string }>) => (
  <ViewProvider>
    <CaseDetail {...props} />
  </ViewProvider>
));
