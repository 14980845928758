import { Company } from '@adac/core-model';
import {
  action,
  computed, observable,
} from 'mobx';


export default class CompanyStore {
  @observable private me: Company | undefined;


  @computed public get getMe() {
    return this.me;
  }

  @action public setMe(me?: Company) {
    this.me = me;
  }

  @action public clear() {
    this.me = undefined;
  }
}
