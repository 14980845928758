import {
  Company,
  Driver, DriverFormValues, getApiRoutes, request,
} from '@adac/core-model';

export const createDriver = async (data: Driver, token: string) => {
  const url = getApiRoutes().driverAdmin.create;
  const res = await request<{}, Driver>(url, 'POST', {
    Authorization: `Bearer ${token}`,
  }, data);
  return res;
};

export const updateDriver = async (driverId: string | number, data: DriverFormValues, token: string) => {
  const url = getApiRoutes().driverAdmin.get(driverId);
  const res = await request(url, 'PUT', {
    Authorization: `Bearer ${token}`,
  }, data);
  return res;
};

export const deleteDriver = async (driverId: string | number, token: string) => {
  const url = getApiRoutes().driverAdmin.get(driverId);
  try {
    const res = await request(url, 'DELETE', {
      Authorization: `Bearer ${token}`,
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const getMyCompany = async (authToken: string) => {
  const url = getApiRoutes().partner.getMyCompany;
  return request<Company>(url, 'GET', {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authToken || ''}`,
  });
};
