import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';

import {
  TableView, CellItem, TextField, View, Text, Button, ButtonTitle, Icon, themeConfig,
} from '@adac/core-view';
import { __, Children } from '@adac/core-model';

import Pagination from './Pagination';

import StoreContext from '../stores';


const Styles = styled.div``;

const SearchAndFilterArea = styled(View)`
  display: flex;
  &>* {
    flex-grow: 1;
  }
`;

const FilterStyles = styled(View)`
  display: flex;
  align-items: center;
  flex-grow: 0;

  &>*:first-child {
    margin-left: 16px;
    margin-right: 4px;
  }
`;

const FilterText = styled(Text)`
  font-weight: bold;
`;

const Filter = () => (
  <FilterStyles>
    <Icon name="filter" />
    <FilterText>{__('Filter')}</FilterText>
  </FilterStyles>
);

const ListPagination = styled(Pagination)`
  margin-top: 44px;
`;

export interface Params {
  page?: number;
  perPage?: number;
  limit?: number;
}

export interface Accessories {
  withSearch?: boolean;
  withFilter?: boolean;
  withPagination?: boolean;
  withExport?: boolean;
}

interface CellItemListWithHeaders {
  cellItems: CellItem[];
  total?: number;
}

interface Props extends Accessories {
  getList: (token: string, params?: Params) => Promise<CellItemListWithHeaders>;
  orderView?: (index: number) => JSX.Element;
  children?: Children;
}

export default (props: Props) => {
  const {
    getList, withSearch, withFilter, withPagination, withExport, orderView, children,
  } = props;

  const stores = useContext(StoreContext);

  const [cellItems, setCellItems] = useState<CellItem[]>([]);

  const [searchTerm, setSEarchTerm] = useState('');

  const [pages, setPages] = useState(1);
  const [more, hasMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage] = useState(15);

  useEffect(() => {
    let isMounted = true;
    const getResource = async () => {
      const params = withPagination
        ? {
          page: currentPage,
          perPage,
        }: undefined;

      // TODO: this avoids memory leaks, but it's ugly and boilerplatey. Use useAsync package?
      try {
        const items = await getList(`${stores.auth.token}`, params);
        hasMore(items.cellItems.length<(items.total || 0));
        if (isMounted && withPagination) {
          setPages(Math.ceil((items.total || items.cellItems.length) / perPage));
        }
        if (isMounted) {
          setCellItems(items.cellItems);
        }
      } catch (error) {
        console.error(error);
      }

      return () => { isMounted = false; };
    };
    getResource();
  }, [currentPage, setCellItems, getList, perPage, withPagination, stores.auth.token]);

  const exportCSV = () => {
    const rows = cellItems.map(item => ([
      item.title,
    ]));
    const csvContent = `data:text/csv;charset=utf-8,${
      rows.map(e => e.join(',')).join('\n')}`;
    const encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  };

  return (
    <Styles>
      {(withSearch || withFilter) && (
        <SearchAndFilterArea>
          {withSearch && (
            <TextField
              badgeTitle={__('Search')}
              badgeEqualsPlaceholder
              value={searchTerm}
              onChange={e => setSEarchTerm(e.target.value)}
              utilityView={<Icon name="search" color={themeConfig.colors.border} />}
            />
          )}
          {withFilter && (
            <Filter />
          )}
        </SearchAndFilterArea>
      )}

      {withExport && (
        <Button onClick={exportCSV}>
          <ButtonTitle>{__('Export')}</ButtonTitle>
        </Button>
      )}

      <TableView
        allCellsOpen
        cellItems={cellItems}
        rightView={<Icon name="right" />}
        orderView={orderView}
      />

      {withPagination && (
        <ListPagination pages={pages} onIndexChange={setCurrentPage} />
      )}

      {more && children}
    </Styles>
  );
};
