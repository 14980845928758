import React, { useContext } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';

import {
  SubPage, Title, View, Text, Button, ButtonTitle, Icon,
} from '@adac/core-view';
import { __ } from '@adac/core-model';

import ClosedCasesList from '../composite/ClosedCasesList';
import OpenCasesList from '../composite/OpenCasesList';
import DriverList from '../composite/DriverList';
import StoresContext from '../../stores';

const Styles = styled.div``;

const SectionTitle = styled(Title)`
  margin-bottom: 24px;
`;

const Section = styled(SubPage)`
  margin-bottom: 40px;
`;

const ShowAllStyles = styled(View)`
  display: flex;
  align-items: center;
`;

const ShowAllText = styled(Text)`
  font-weight: bold;
  margin-right: 8px;
`;

const ShowAll = ({ link }: { link: string }) => (
  <Button link={link}>
    <ShowAllStyles>
      <ShowAllText>{__('Show All')}</ShowAllText>
      <Icon name="right" />
    </ShowAllStyles>
  </Button>
);

export default observer(() => {
  const { auth: authStore } = useContext(StoresContext);

  return (
    <Styles>
      <Section>
        <SectionTitle>{__('Current Cases')}</SectionTitle>
        <OpenCasesList limit={5}>
          <ShowAll link="/cases/open" />
        </OpenCasesList>
      </Section>
      <Section>
        <SectionTitle>{__('Your Company')}</SectionTitle>
        <DriverList />
        {authStore.isUserRoleBoss && (
          <Button info link="/driver/new">
            <ButtonTitle>{__('Add new driver')}</ButtonTitle>
          </Button>
        )}
      </Section>
      <Section>
        <SectionTitle>{__('All Closed Cases')}</SectionTitle>
        <ClosedCasesList limit={5}>
          <ShowAll link="/cases/closed" />
        </ClosedCasesList>
      </Section>
    </Styles>
  );
});
