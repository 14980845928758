import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import {
  Page, SubPage, Title, SubTitle, useLocation, ErrorMessage, DriverDeleteButton, useValidationSchema, AddressOverlayContainer, ManageDriver,
  themeConfig,
} from '@adac/core-view';

import {
  isDriverBoss,
  UserRole,
  __,
} from '@adac/core-model';

import styled from 'styled-components';
import {
  createDriver, updateDriver, deleteDriver,
} from '../../networking/driverEndpoints';
import StoreContext from '../../stores';
import useSelectedDriver from '../../hooks/useSelectedDriver';
import { useMyCompany } from '../../hooks/useMyCompany';

const DeleteButton = styled(DriverDeleteButton)`
  border: 1px solid ${themeConfig.colors.warning};
  margin: 16px 0px;
  padding: 13px 0px;
  border-radius: 4px;
`;


const ManageDrivers = (props: RouteComponentProps<{ driverId: string; action: string }>) => {
  const { auth: authStore, ui: uiStore } = useContext(StoreContext);
  const { goBack } = useLocation();
  const myCompany = useMyCompany();

  const driverId = props.match.params.driverId;
  const isNewDriver = props.match.params.action === 'new' || !driverId;
  const driver = useSelectedDriver(driverId);
  const validationSchema = useValidationSchema(driver);

  const onSubmit = async (driverParam: any) => {
    try {
      // TODO: useAsync?
      if (driverId) {
        await updateDriver(
          driverId,
          driverParam,
          authStore.token || '',
        );
      } else {
        await createDriver(driverParam, authStore.token || '');
      }
      goBack();
    } catch (error) {
      uiStore.setOverlay(<ErrorMessage>{__(error.toLocalizedString ? error.toLocalizedString() : `${error}`)}</ErrorMessage>);
    }
  };

  const onDeleteDriver = async () => {
    await deleteDriver(driverId, authStore.token || '');
    goBack();
  };

  if (driverId && !Number.isNaN(driverId) && !driver) return <SubTitle>{__('Driver not found')}</SubTitle>;

  // only boss can edit boss data
  if (!authStore.isUserRoleBoss && isDriverBoss(driver)) {
    return <ErrorMessage>{__('You do not have permission to edit boss')}</ErrorMessage>;
  }
  return (
    <Page>
      <AddressOverlayContainer>
        <SubPage>
          <Title>
            {isNewDriver ? 'Neuen Mitarbeiter hinzufügen' : `${driver?.firstName} ${driver?.familyName}` }
          </Title>
          {((driver !== undefined || isNewDriver) && myCompany) && (
            <ManageDriver
              companyId={myCompany.id}
              driver={driver}
              onSubmit={onSubmit}
              onBack={goBack}
              validationSchema={validationSchema}
              disablePrivatePhoneForBoss
            >
              {!driver?.user?.roles?.find(role => role.name === UserRole.COMPANY) && !isNewDriver && <DeleteButton onClick={onDeleteDriver} />}
            </ManageDriver>
          )}
        </SubPage>
      </AddressOverlayContainer>

    </Page>
  );
};

export default ManageDrivers;
