import { useContext, useState, useEffect } from 'react';
import {
  request, Driver, DriverRecord, getApiRoutes,
} from '@adac/core-model';
import StoreContext from '../stores';

const useSelectedDriver = (driverId: string): DriverRecord | undefined => {
  const { auth: { token } } = useContext(StoreContext);
  const [driver, setDriver] = useState<Driver>();

  useEffect(() => {
    const getDriver = async () => {
      const url = getApiRoutes().driverAdmin.get(driverId);
      try {
        const data = await request<Driver>(url, 'GET', {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token || ''}`,
        });
        setDriver(data);
      } catch (error) {
        console.error(error);
      }
    };
    if (!Number.isNaN(parseInt(driverId as string, 10))) {
      getDriver();
    }
  }, [driverId, token]);

  return driver;
};

export default useSelectedDriver;
