import React, {
  useContext,
} from 'react';

import {
  RoundImage,
  Icon,
  ErrorMessage,
} from '@adac/core-view';
import { __ } from '@adac/core-model';

import ListView from '../ListView';
// import { Description } from './CaseList';

import { getDrivers } from '../../networking/getDrivers';
import StoresContext from '../../stores';

const mapRole = (role: string) => {
  if (role === 'company') return __('Role:company');
  return role;
};

export const getDriversIntoCellItems = async (token: string, dispacherCallback: (dispatchers: number) => void) => {
  const drivers = await getDrivers(token);
  let dispatchers = 0;

  const cellItems = drivers.data.map((d) => {
    const roles = ((d.user?.roles ?? []) as { name: string}[]).map(({ name }: { name: string }) => name);
    if (roles.includes('dispatcher')) {
      dispatchers += 1;
    }

    return ({
      title: `${d.firstName} ${d.familyName}`,
      description: `${d.user ? mapRole(d.user.roles[0].name) : __('Driver')}`,
      key: `${d.id}`,
      customOrderView: <RoundImage image={d.photo} placeholder={<Icon name="person" />} />,
      link: `/driver/edit/${d.id}`,
    });
  });

  dispacherCallback(dispatchers);
  return { cellItems, total: drivers.total };
};

export default () => {
  const { ui: uiStore } = useContext(StoresContext);

  const dispatcherCounterCallback = (dispatchers: number) => {
    if (dispatchers > 1) {
      uiStore.setOverlay(<ErrorMessage>{__('Warning: You have 2 dispatchers defined for your company')}</ErrorMessage>);
    }
  };

  return (
    <ListView
      getList={(token: string) => getDriversIntoCellItems(token, dispatcherCounterCallback)}
    />
  );
};
