import React from 'react';

import { Icon, themeConfig } from '@adac/core-view';

import { Children } from '@adac/core-model';
import { GetterType } from '../../networking/getCases';
import { getCasesIntoCellItems } from './caseListComponents';
import ListView, { Accessories, Params } from '../ListView';

export interface ListLimitProps extends Accessories {
  limit?: number;
  children?: Children;
}
 interface CaseListProps extends ListLimitProps {
  getter: (token: string, params?: Params) => Promise<GetterType>;
}

export default (props: CaseListProps) => {
  const { limit, getter } = props;

  return (
    <ListView
      {...props}
      orderView={() => <Icon name="document" color={themeConfig.colors.info2} />}
      getList={(token: string, params?: Params) => getCasesIntoCellItems(token, getter, limit ? { perPage: limit, page: 0 }: params)}
    />
  );
};
