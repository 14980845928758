import { CompanyBoss, getApiRoutes, request } from '@adac/core-model';

export const getMe = async (token: string) => {
  const url = getApiRoutes().auth.users.getMe;
  const user = await request<CompanyBoss>(url, 'GET', {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  });
  if (user) return user;
  throw new Error('No user returned');
};
