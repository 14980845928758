import React from 'react';
import styled from 'styled-components';

import {
  Text, View, CellItem, Icon, themeConfig,
} from '@adac/core-view';
import {
  ListWithHeaders, __, ChildrenProps, getClaimReference,
} from '@adac/core-model';


import { Case } from '../../networking/getCases';
import { Params } from '../ListView';

const DescriptionStyles = styled(View)`
  position: relative;
`;
const DescriptionAddress = styled(Text)`
  font-size: 1.6rem;
`;
const DescriptionCaseId = styled(Text)`
  font-size: 1.4rem;
  color: ${props => props.theme.colors.gray};
`;
const InvoiceStyles = styled(View)`
  position: absolute;
  top: 0;
  right: 20px;
  float: right;
`;

interface DescriptionProps extends ChildrenProps {
  address: string;
  caseId: string;
}

export const Description = ({ address, caseId, children }: DescriptionProps) => (
  <DescriptionStyles>
    <DescriptionAddress>{address}</DescriptionAddress>
    <DescriptionCaseId>{caseId}</DescriptionCaseId>
    {children}
  </DescriptionStyles>
);


export const getCasesIntoCellItems = async (accessToken: string, getCases: (token: string, params?: Params) => Promise<ListWithHeaders<Case>>, params?: Params) => {
  // const { REACT_APP_CUSTOMER_BASE_URL: customerUrl } = process.env;
  const cases = await getCases(accessToken, params);
  const cellItems = cases.data.map((c: Case): CellItem => ({
    title: `${c.customerAddressStreet} ${c.customerAddressNumber}`,
    description: (
      <Description
        caseId={`${__('Hermes Id')}: ${getClaimReference(c.commissioningReference)}`}
        address={`${c.customerAddressZip} ${c.customerAddressCity}`}
      >
        {c.invoiceDocument && <InvoiceStyles><Icon name="invoice" color={themeConfig.colors[c.invoiceDocument ? 'info2' : 'divider']} /></InvoiceStyles>}
      </Description>
    ),
    key: `${c.id}`,
    link: `/cases/${c.cypheredToken}`,
  }));

  return { cellItems, total: cases.total };
};
