import React, {
// useContext,
} from 'react';
import { Security, RouterContextContainer } from '@adac/core-view';
import { Route } from 'react-router-dom';
import { LayoutContainer } from './components/layout';
import StoresContext from './stores';
import { setupNetworking } from './networking/setupNetworking';


// eslint-disable-next-line arrow-body-style
const App = () => {
  // const { case: caseStore } = useContext(StoresContext);
  const { auth: authStore } = React.useContext(StoresContext);

  // async function setCaseToken(urlToken: string) {
  //   console.debug('FYI, setCaseToken received, not in use', urlToken);
  //   const { token } = await caseStore.fetchByToken(urlToken, () => console.log('no token!'));
  //   console.log('TCL: setCaseToken -> token', token);
  //   if (urlToken !== token && token) {
  //     console.log('TODO: smarter redirect than location.href=/', token, 'from ', window.location.pathname);
  //     window.location.href=`/${token}`;
  //   }
  // }

  setupNetworking(authStore);

  return (
    <Security>
      <RouterContextContainer
        // setCaseToken={setCaseToken}
        routes={[<Route path="/login"><h1>ERROR 500</h1> TODO: implement Login form </Route>]}
      >
        <LayoutContainer />
      </RouterContextContainer>
    </Security>
  );
};

export default App;
